.hero-section {
  padding-top: 5rem;
}
.hero-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.img-box {
  width: 100%;
  position: relative;
}

.img-box--img {
  width: 100%;
}

.img--overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffd5;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.img--overlay > * {
  transform: translateY(30px);
  transition: transform 0.25s ease;
}

.img--overlay:hover {
  opacity: 1;
}
.img--title {
  font-size: 5rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.7rem;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
}
.img--text {
  width: 55%;
}

.img--overlay:hover > * {
  transform: translateY(0);
}
.img--text--box {
  display: none;
}

@media screen and (max-width: 1100px) {
  .img--title {
    font-size: 4rem;
  }
  .img--text {
    width: 70%;
  }
}
@media screen and (max-width: 770px) {
  .hero-section {
    padding-top: 11rem;
  }
  .img--overlay {
    display: none;
  }
  .img--text--box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
  }

  .img--title {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
  .img--text {
    width: 90%;
  }
}
@media screen and (max-width: 550px) {
  .img--text {
    width: 95%;
  }
  .img--title {
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
  .img--text {
    font-size: 1.6rem;
    padding: 2rem;
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .hero-section {
    padding: 11rem 0 3rem 0;
  }
  .img--title {
    font-size: 2.4rem;
    letter-spacing: 0.4rem;
  }
}
