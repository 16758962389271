.contact-content {
  max-width: 100rem;
  margin: 0 auto;
  display: flex;
  gap: 3rem;
}
/* colonne 1 */

.contact-list {
  list-style: none;
}

.contactus-text {
  font-size: 3rem;
  color: #222;
}
.contactus-text span {
  font-weight: 300;
}

.contact-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
}
.contact-items:first-child {
  margin: 1.5rem 0 5rem 0;
}
.contact-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #222;
}

.contact-item {
  font-size: 1.8rem;
  margin-left: 1.5rem;
  font-weight: 300;
  transition: all 0.2s ease-in-out;
}
.contact-item:hover {
  font-weight: 400;
}
.contact-item-green:hover {
  color: var(--green);
}
.contact-icon {
  font-size: 2rem;
  margin-left: 1rem;
  color: #222;
}

.contact-icon-insta {
  font-size: 1.7rem;
}
.contact-icon-insta-green {
  color: var(--green);
}

.pobox {
  margin-top: 0.5rem;
}

/* colonne 2 FORM */

form {
  margin: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-container input,
.form-container textarea {
  text-align: center;
  font-family: inherit;
}
.form-container input {
  display: block;
  border-radius: none;
  background-color: transparent;
  width: 65%;
  height: 3.5rem;
  margin: 3rem auto 0;
  padding: 0.8rem;
  text-align: center;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid black;
  color: #222;
  outline: 0;
  transition: width 0.6s ease 0.2s;
}
.form-container input:hover {
  width: 90%;
}
input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #222;
  font-weight: 500;
  letter-spacing: 0.5px;
  opacity: 1; /* Firefox */
}
textarea::placeholder {
  letter-spacing: 0.5px;
}

.form-container textarea {
  width: 65%;
  padding: 2rem 1rem;
  margin: 6rem 0 4rem;
  border-radius: 0;
  border: 2px solid black;
}

.btn-form {
  align-self: center;
  padding: 1rem 2rem;
  width: 65%;
  height: 5rem;
  font-family: inherit;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #222;
  background-color: #fff;
  border: 2px solid #222;

  letter-spacing: 2px;

  font-weight: 700;
  transition: all 0.2s ease-in;
}
.btn-form:hover {
  background-color: #222;
  color: #fff;
}
.contact-content {
  margin-top: 21rem;
}

@media screen and (max-width: 1100px) {
  .contact-content {
    margin-top: 15rem;
  }
}
@media screen and (max-width: 1000px) {
  .contact-content {
    margin-top: 0;
  }
  .contact-content {
    flex-direction: column;
  }

  .contact-list {
    padding: 0 1rem;
  }
  .contact-item {
    font-size: 1.6rem;
    font-weight: 300;
  }
  .form-container {
    width: 100%;
    margin-bottom: 5rem;
  }
  .form-container h1 {
    font-size: 2.3rem;
    font-weight: 400;
  }
}
@media screen and (max-width: 770px) {
  .contact-item {
    font-size: 2rem;
  }
  .form-container input,
  .form-container textarea {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 550px) {
  .form-container input,
  .form-container textarea,
  .btn-form {
    width: 75%;
  }
  .contact-section {
    padding-top: 12rem;
  }
  .contact-icon {
    margin-left: 1.5rem;
  }
  .form-container {
    padding: 0;
  }
}
