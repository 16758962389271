.section {
  margin-left: 35rem;
  padding: 8rem 7rem 5rem;
}
.section-notFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo-spinning {
  width: 3rem;

  animation: logo-spin infinite 10s linear;
}
.icon-flower {
  width: 4.5rem;
  margin-bottom: -2rem;
  margin-right: 1rem;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.display-linebreak {
  white-space: pre-line;
}
.global-flex {
  display: flex;
  flex-wrap: wrap;
}
.primary-headline {
  text-transform: uppercase;
  font-weight: 200;
  text-align: center;
  font-size: 3.5rem;
  letter-spacing: 1px;
  margin-bottom: 5rem;
}
.bodytext {
  font-size: 1.8rem;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: #4b4b4b;
}

.margin-top {
  margin-top: 10rem;
}
.not-found {
  font-family: "Times New Roman", Times, serif;
  font-size: 2rem;
  font-weight: bold;
  text-transform: none;
  margin-top: 3rem;
  margin-bottom: 60vh;
}
.green {
  color: var(--green);
}
.uppercase {
  text-transform: uppercase;
}

@media screen and (max-width: 1100px) {
  .section {
    margin-left: 25rem;
    padding: 10rem 4rem;
  }
}
@media screen and (max-width: 770px) {
  .section {
    padding-top: 13rem;
    margin-left: 0;
  }
}

@media screen and (max-width: 550px) {
  .section {
    padding: 10rem 0 3rem 0;
  }
  .primary-headline {
    font-size: 3rem;
    padding: 2rem;
    margin-bottom: 3rem;
  }
  .bodytext {
    padding: 0 2rem;
  }
  .logo-spinning {
    width: 2.5rem;
    margin-bottom: -1rem;
  }
  .icon-flower {
    width: 3.5rem;
    margin-bottom: -2.3rem;
  }
}

/* query selector: 1100 / 770 / 550 / 450 */
