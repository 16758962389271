.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 1rem;

  margin-bottom: 4rem;
}
.canvas {
  overflow: hidden;
}

.canvas > img {
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  object-fit: cover;

  transition: transform 250ms;
}

.canvas img:hover {
  transform: scale(1.1);
}
.canvas-icon {
  height: 2.2rem;
  margin-right: 0.9rem;
}

/* Painting Page css */
/* mySwiperCard */

.mySwiperCard {
  margin-bottom: 5rem;
}

.mySwiperCard .slider-controler {
  display: none;
}

.swiper-slide-painting {
  height: 100%;
  width: 100%;
  align-self: center;
  position: relative;
}
.swiper-slide-painting img {
  margin-bottom: 3rem;
  background-color: transparent;
}
.images-icon {
  display: none;
  color: #fff;
  font-size: 3rem;
  position: absolute;
  bottom: 5rem;
  right: 1.5rem;
  z-index: 111;
}

/* text  */

.img-painting-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 35rem;
  padding: 0 2rem;
}
.canvas-description {
  max-width: 80rem;
  margin-bottom: 15rem;
}
.painting-dimension {
  font-size: 1.4rem;
}
.link-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.link-back-btn {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  letter-spacing: 1.6px;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  transition: all 200ms;
  margin-top: 6rem;
  margin-left: 1.8rem;
}
.link-back-btn span {
  margin-left: 1rem;
}
.link-back-btn:hover {
  font-weight: 800;
}
.arrow {
  font-size: 3rem;
}
.back-arrow {
  font-size: 1.4rem;
}
.link-back-btn:hover .back-arrow {
  font-size: 1.6rem;
}

@media screen and (max-width: 750px) {
  .img-painting-text {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .canvas-section {
    padding-top: 12rem;
  }
  .img-painting-box {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .img-painting-text {
    padding: 0 1rem;
  }
  .images-icon {
    display: block;
  }
}
