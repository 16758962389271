.footer-section {
  border-top: 3px solid rgba(0, 0, 0, 0.059);
  padding-top: 3rem;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
}
.social__media_list {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin-bottom: 3rem;
}
.social__media_link {
  font-size: 2.3rem;

  color: black;
}
.social__media_icon-linkedin,
.social__media_icon-facebook {
  font-size: 2.8rem;
}

.space {
  font-weight: 200;
  font-size: 2.5rem;
  padding: 0 1rem;
}
/* footer-signature */
.footer-signature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.copyright {
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 0 1rem;
  text-align: center;
}
.copyright:last-child {
  font-size: 1rem;
}

.sand-signature {
  color: black;
}
@media screen and (max-width: 550px) {
  .social__media_list {
    gap: 2rem;
  }
  .social__media_link {
    font-size: 2rem;
  }
  .social__media_icon-linkedin,
  .social__media_icon-facebook {
    font-size: 2.5rem;
  }

  .space {
    font-size: 2.2rem;
  }
}
